import { jsx as _jsx, Fragment as _Fragment, jsxs as _jsxs } from "react/jsx-runtime";
import { Badge } from "./badge";
import { Button } from "./button";
import { Calendar } from "./calendar";
import { Tabs, TabsContent, TabsList, TabsTrigger } from "./tabs";
import { Command, CommandEmpty, CommandGroup, CommandInput, CommandItem, CommandList, CommandSeparator, } from "./command";
import { Dialog, DialogContent, DialogDescription, DialogHeader, DialogTitle, DialogTrigger, } from "./dialog";
import { Separator } from "./separator";
import { Drawer, DrawerClose, DrawerContent, DrawerDescription, DrawerFooter, DrawerHeader, DrawerTitle, DrawerTrigger, } from "./drawer_dialog";
import { ExpandablePostCall, useExpandablePostCall } from "./expandable";
import { PencilIcon } from "@heroicons/react/24/outline";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./hover_card";
import { StringListEditor } from "./string_list_editor";
import ItemDetailsDrawer from "./item_details_drawer";
import React, { useState } from "react";
import { MultiSelector, MultiSelectorContent, MultiSelectorInput, MultiSelectorItem, MultiSelectorList, MultiSelectorTrigger, } from "./multi_select";
import { RadioGroup, RadioGroupItem } from "./radio_group";
import { useToast } from "./use_toast";
import { DropdownMenu, DropdownMenuTrigger, DropdownMenuItem, DropdownMenuLabel, DropdownMenuSeparator, DropdownMenuContent, } from "./dropdown_menu";
import { Select, SelectContent, SelectItem, SelectTrigger, SelectValue, } from "./select";
import { ContactAvatar } from "../common/contact_avatar";
import { Checkbox } from "./checkbox";
import { Alert, AlertDescription, AlertTitle } from "./alert";
const badgeVariants = [
    "default",
    "secondary",
    "destructive",
    "outline",
];
const buttonVariants = [
    "default",
    "destructive",
    "outline",
    "secondary",
    "ghost",
    "link",
    "light",
];
const ShowMoreButton = () => {
    const { setShowMore } = useExpandablePostCall(); // Use the context to access the toggle function
    return (_jsx("button", Object.assign({ onClick: () => {
            if (setShowMore) {
                setShowMore(true);
            }
        } }, { children: _jsx(PencilIcon, { className: "w-5 h-5 text-wds-blue-3" }) })));
};
const StringListEditorExample = () => {
    const [selectedStrings, setSelectedStrings] = useState([
        "string1",
        "string2",
    ]);
    return (_jsx(StringListEditor, { onChange: (strings) => setSelectedStrings(strings), selectedStrings: selectedStrings, wrapPills: true }));
};
const ItemDetailsDrawerExample = () => {
    const [isOpen, setIsOpen] = React.useState(false);
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ onClick: () => setIsOpen(true) }, { children: "Open Item Details" })), _jsx(ItemDetailsDrawer, { title: "Item Details", details: [
                    { title: "Name", value: "John Doe" },
                    {
                        title: "Email",
                        value: _jsx("div", Object.assign({ className: "text-wds-blue-3" }, { children: "joe@gmail.com" })),
                    },
                ], isOpen: isOpen, onClose: () => setIsOpen(false), deleteCallback: () => {
                    console.log("Delete");
                    return Promise.resolve();
                }, editCallback: () => {
                    console.log("Edit");
                } })] }));
};
const MultiSelectExample = () => {
    const [value, setValue] = React.useState([]);
    return (_jsxs(MultiSelector, Object.assign({ values: value, onValuesChange: setValue, loop: true, className: "max-w-xs" }, { children: [_jsx(MultiSelectorTrigger, { children: _jsx(MultiSelectorInput, { placeholder: "Select CRM account" }) }), _jsx(MultiSelectorContent, { children: _jsxs(MultiSelectorList, { children: [_jsx(MultiSelectorItem, Object.assign({ value: "anirudh@getwiser.io" }, { children: "Anirudh" })), _jsx(MultiSelectorItem, Object.assign({ value: "nitin@getwiser.io" }, { children: "Nitin" })), _jsx(MultiSelectorItem, Object.assign({ value: "harsh@getwiser.io" }, { children: "Harsh" }))] }) })] })));
};
export function ToastDemo() {
    const { toast } = useToast();
    return (_jsxs(_Fragment, { children: [_jsx(Button, Object.assign({ variant: "outline", onClick: () => {
                    toast({
                        title: "Scheduled: Catch up ",
                        description: "Friday, February 10, 2023 at 5:57 PM",
                    });
                } }, { children: "Add to calendar" })), _jsx(Button, Object.assign({ variant: "outline", onClick: () => {
                    toast({
                        title: "Scheduled: Catch up ",
                        description: "Friday, February 10, 2023 at 5:57 PM",
                        variant: "green",
                    });
                } }, { children: "Add to calendar green" }))] }));
}
const ContactAvatarExamples = () => {
    const [showName, setShowName] = useState(false);
    const [showEmail, setShowEmail] = useState(false);
    return (_jsxs("div", Object.assign({ className: "flex flex-col gap-2" }, { children: [_jsxs("div", Object.assign({ className: "flex gap-4" }, { children: [_jsxs("div", Object.assign({ className: "h-6 justify-start items-center inline-flex gap-1" }, { children: [_jsx(Checkbox, { checked: showName, onCheckedChange: (e) => setShowName(e) }), "Show name"] })), _jsxs("div", Object.assign({ className: "h-6 justify-start items-center inline-flex gap-1" }, { children: [_jsx(Checkbox, { checked: showEmail, onCheckedChange: (e) => setShowEmail(e) }), "Show email"] }))] })), _jsx(ContactAvatar, { contact: {
                    id: 1,
                    email: "harsh@getwiser.io",
                    contact_name: "Harsh Gupta",
                    full_name: "Harsh Gupta",
                    linkedin_profile_url: "",
                    crm_data: [],
                    profile_pic_url: "https://images.unsplash.com/photo-1492633423870-43d1cd2775eb?&w=128&h=128&dpr=2&q=80",
                }, showName: showName, showEmail: showEmail })] })));
};
const ComponentExamples = () => {
    const exampleNameAndContent = [
        {
            name: "Badge",
            content: (_jsx("div", { children: badgeVariants.map((variant) => (_jsx(Badge, Object.assign({ variant: variant, className: "mr-4" }, { children: variant }), variant))) })),
        },
        {
            name: "Button",
            content: (_jsx("div", { children: buttonVariants.map((variant) => (_jsx(Button, Object.assign({ variant: variant, className: "mr-4" }, { children: variant }), variant))) })),
        },
        {
            name: "Calendar",
            content: (_jsx(Calendar, { mode: "single", selected: new Date(), onSelect: (date) => console.log(date) })),
        },
        {
            name: "Checkbox",
            content: (_jsxs("div", { children: [_jsx(Checkbox, { checked: true, onChange: (e) => console.log(e) }), _jsx(Checkbox, { checked: false, onChange: (e) => console.log(e) }), _jsx(Checkbox, { checked: true, disabled: true, onChange: (e) => console.log(e) })] })),
        },
        {
            name: "Toast",
            content: _jsx(ToastDemo, {}),
        },
        {
            name: "Command",
            content: (_jsxs(Command, { children: [_jsx(CommandInput, { placeholder: "Type a command or search..." }), _jsxs(CommandList, { children: [_jsx(CommandEmpty, { children: "No results found." }), _jsxs(CommandGroup, Object.assign({ heading: "Suggestions" }, { children: [_jsx(CommandItem, { children: "Calendar" }), _jsx(CommandItem, { children: "Search Emoji" }), _jsx(CommandItem, { children: "Calculator" })] })), _jsx(CommandSeparator, {}), _jsxs(CommandGroup, Object.assign({ heading: "Settings" }, { children: [_jsx(CommandItem, { children: "Profile" }), _jsx(CommandItem, { children: "Billing" }), _jsx(CommandItem, { children: "Settings" })] }))] })] })),
        },
        {
            name: "Dialog",
            content: (_jsxs(Dialog, { children: [_jsx(DialogTrigger, { children: "Open" }), _jsx(DialogContent, { children: _jsxs(DialogHeader, { children: [_jsx(DialogTitle, { children: "Are you absolutely sure?" }), _jsx(DialogDescription, { children: "This action cannot be undone. This will permanently delete your account and remove your data from our servers." })] }) })] })),
        },
        {
            name: "Drawer",
            content: (_jsxs(Drawer, { children: [_jsx(DrawerTrigger, { children: "Open" }), _jsxs(DrawerContent, { children: [_jsxs(DrawerHeader, { children: [_jsx(DrawerTitle, { children: "Are you absolutely sure?" }), _jsx(DrawerDescription, { children: "This action cannot be undone. This will permanently delete your account and remove your data from our servers." })] }), _jsxs(DrawerFooter, { children: [_jsx(Button, { children: "Submit" }), _jsx(DrawerClose, { children: _jsx(Button, Object.assign({ variant: "outline" }, { children: "Cancel" })) })] })] })] })),
        },
        {
            name: "Separator",
            content: (_jsxs("div", Object.assign({ className: "flex flex-col space-y-4 items-center" }, { children: [_jsx(Separator, { orientation: "horizontal" }), _jsx(Separator, { orientation: "vertical", className: "h-14" })] }))),
        },
        {
            name: "Tabs",
            content: (_jsxs(Tabs, Object.assign({ defaultValue: "account", className: "w-[400px]", onValueChange: (e) => console.log(e) }, { children: [_jsxs(TabsList, { children: [_jsx(TabsTrigger, Object.assign({ value: "account" }, { children: "Account" })), _jsx(TabsTrigger, Object.assign({ value: "password" }, { children: "Password" }))] }), _jsx(TabsContent, Object.assign({ value: "account" }, { children: "Make changes to your account here." })), _jsx(TabsContent, Object.assign({ value: "password" }, { children: "Change your password here." }))] }))),
        },
        {
            name: "Expandable",
            content: (_jsx(ExpandablePostCall, { alwaysShowExpandButton: true, showLessContent: _jsxs("div", { children: ["Show when collapsed", _jsx(ShowMoreButton, {}), [1, 2, 3, 4, 5].map((i) => (_jsxs("div", { children: ["Line ", i] }, i)))] }), showMoreContent: _jsxs(_Fragment, { children: [_jsx("span", { children: "Show when expanded" }), [1, 2, 3, 4, 5].map((i) => (_jsxs("div", { children: ["Line ", i] }, i)))] }) })),
        },
        {
            name: "HoverCard",
            content: (_jsxs(HoverCard, { children: [_jsx(HoverCardTrigger, { children: "Triggers when you hover over this" }), _jsx(HoverCardContent, { children: "Content shown on hover" })] })),
        },
        {
            name: "StringListEditor",
            content: _jsx(StringListEditorExample, {}),
        },
        {
            name: "ItemDetailsDrawer",
            content: _jsx(ItemDetailsDrawerExample, {}),
        },
        {
            name: "MultiSelect",
            content: _jsx(MultiSelectExample, {}),
        },
        {
            name: "RadioGroup",
            content: (_jsx(RadioGroup, Object.assign({ onValueChange: (value) => console.log(value), defaultValue: "2" }, { children: [1, 2, 3].map((i) => (_jsxs("div", Object.assign({ className: "flex items-center space-x-3" }, { children: [_jsx(RadioGroupItem, { value: i.toString() }, i), _jsxs("span", { children: ["Option ", i] })] }), i))) }))),
        },
        {
            name: "Dropdown",
            content: (_jsxs(DropdownMenu, { children: [_jsx(DropdownMenuTrigger, { children: "Open" }), _jsxs(DropdownMenuContent, { children: [_jsx(DropdownMenuLabel, { children: "My Account" }), _jsx(DropdownMenuSeparator, {}), _jsx(DropdownMenuItem, { children: "Profile" }), _jsx(DropdownMenuItem, { children: "Billing" }), _jsx(DropdownMenuItem, { children: "Team" }), _jsx(DropdownMenuItem, { children: "Subscription" })] })] })),
        },
        {
            name: "Select",
            content: (_jsxs(Select, Object.assign({ onValueChange: (value) => console.log(value) }, { children: [_jsx(SelectTrigger, Object.assign({ className: "w-[180px]" }, { children: _jsx(SelectValue, { placeholder: "Theme" }) })), _jsxs(SelectContent, { children: [_jsx(SelectItem, Object.assign({ value: "light" }, { children: "Light" })), _jsx(SelectItem, Object.assign({ value: "dark" }, { children: "Dark" })), _jsx(SelectItem, Object.assign({ value: "system" }, { children: "System" }))] })] }))),
        },
        {
            name: "ContactAvatar",
            content: _jsx(ContactAvatarExamples, {}),
        },
        {
            name: "Alert",
            content: (_jsxs(Alert, { children: [_jsx(AlertTitle, { children: "Hi, this is an alert!" }), _jsx(AlertDescription, { children: "Hi, this is an alert description." })] })),
        },
    ];
    return (_jsxs("div", Object.assign({ className: "h-screen w-full p-16 sticky bg-white" }, { children: [_jsx("h1", Object.assign({ className: "text-3xl font-bold mb-8" }, { children: "Component Examples" })), _jsx("div", Object.assign({ className: "grid grid-cols-1 gap-8" }, { children: exampleNameAndContent.map((example, index) => (_jsxs("div", Object.assign({ className: "flex flex-col gap-4 pb-8" }, { children: [_jsx("h2", Object.assign({ className: "text-2xl font-bold" }, { children: example.name })), _jsx("div", Object.assign({ className: "p-4 border border-gray-300 rounded-lg" }, { children: example.content }))] }), index))) }))] })));
};
export default ComponentExamples;
