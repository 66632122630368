import { jsx as _jsx, jsxs as _jsxs } from "react/jsx-runtime";
import classNames from "../class_names";
import { Badge } from "../components/badge";
import { Checkbox } from "../components/checkbox";
import { INTEGRATION_DETAILS } from "../post_call/data_processing_config";
export const BrickCard = ({ brick, isSelected, setIsSelected, dataSourceConfig, }) => {
    var _a, _b, _c, _d, _e, _f;
    const dataSourceIdToLabelMap = new Map(dataSourceConfig.map((ds) => [ds.id, ds.label]));
    const dataSourcesToRender = [
        ...new Set(brick.data_sources.map((brickDataSource) => {
            const config = dataSourceConfig.find((ds) => ds.id === brickDataSource);
            if (!config)
                return undefined;
            if (!config.parent_id) {
                return config.label;
            }
            return dataSourceIdToLabelMap.get(config.parent_id);
        })),
    ];
    const supportedDestinations = [];
    if (["TRACKER"].includes(brick.research_block_type)) {
        supportedDestinations.push({
            name: "Salesforce",
            iconUrl: (_a = INTEGRATION_DETAILS.get("Salesforce")) === null || _a === void 0 ? void 0 : _a.icon_url,
        }, {
            name: "HubSpot",
            iconUrl: (_b = INTEGRATION_DETAILS.get("HubSpot")) === null || _b === void 0 ? void 0 : _b.icon_url,
        }, {
            name: "Slack",
            iconUrl: (_c = INTEGRATION_DETAILS.get("Slack")) === null || _c === void 0 ? void 0 : _c.icon_url,
        }, {
            name: "Gmail",
            iconUrl: (_d = INTEGRATION_DETAILS.get("Gmail")) === null || _d === void 0 ? void 0 : _d.icon_url,
        });
    }
    else if (["CONTACT"].includes(brick.research_block_type)) {
        supportedDestinations.push({
            name: "Gmail",
            iconUrl: (_e = INTEGRATION_DETAILS.get("Gmail")) === null || _e === void 0 ? void 0 : _e.icon_url,
        });
    }
    else {
        // all other brick types can only be used internally.
        supportedDestinations.push({
            name: "Internal",
            iconUrl: (_f = INTEGRATION_DETAILS.get("Wiser CRM")) === null || _f === void 0 ? void 0 : _f.icon_url,
        });
    }
    return (_jsx("div", Object.assign({ className: classNames("flex w-[355px] p-6 pt-8 flex-col justify-center items-start", "gap-6 rounded-lg bg-white border border-wds-gray-2 border-t-[12px] shadow-md", isSelected ? "border-t-wds-blue-3" : "") }, { children: _jsxs("div", Object.assign({ className: "flex flex-col items-start gap-4 self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex justify-center items-start gap-4 justify-between self-stretch" }, { children: [_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-1 grow" }, { children: [_jsx("span", Object.assign({ className: "self-stretch text-black font-bold text-sm" }, { children: "Prompt Name:" })), _jsx("span", Object.assign({ className: "text-black self-stretch" }, { children: brick.name }))] })), _jsx(Checkbox, { className: classNames(isSelected ? "border-none" : "border"), checked: isSelected, onCheckedChange: setIsSelected })] })), _jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-1 justify-between self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-sm text-black font-bold self-stretch" }, { children: "Prompt:" })), _jsx("span", Object.assign({ className: "text-black self-stretch line-clamp-3" }, { children: brick.prompt }))] })), dataSourcesToRender.filter((x) => x !== undefined).length > 0 && (_jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-1 self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-sm text-black font-bold self-stretch" }, { children: "Data Sources:" })), _jsx("div", Object.assign({ className: "flex items-start content-start gap-1 self-stretch flex-wrap" }, { children: dataSourcesToRender.map((dataSource, idx) => {
                                if (!dataSource)
                                    return null;
                                return (_jsx(Badge, Object.assign({ className: "flex py-1 px-2 bg-wds-blue-1 text-wds-blue-4 hover:bg-wds-blue-1" }, { children: dataSource }), `${brick.id}-data-source-${idx}`));
                            }) }))] }))), _jsxs("div", Object.assign({ className: "flex flex-col justify-center items-start gap-1 justify-between self-stretch" }, { children: [_jsx("span", Object.assign({ className: "text-sm text-black font-bold self-stretch" }, { children: "Supported Destinations:" })), _jsx("div", Object.assign({ className: "flex items-start content-start gap-2 self-stretch flex-wrap" }, { children: supportedDestinations.map((destination, idx) => {
                                return (_jsx("img", { src: destination.iconUrl, alt: destination.name, className: "w-6 h-6 p-1 border border-wds-gray-3 rounded-sm" }, `${brick.id}-data-source-${idx}`));
                            }) }))] }))] })) })));
};
